.Closed {
  color: white;
  /* border: 1px solid red; */
  border-radius: 12px;
  padding: 0px 10px;
  background-color: black;
  width: fit-content;
  margin: auto;
}

.Emailed {
  color: var(--button-click);
  border: 1px solid var(--button-click);
  border-radius: 12px;
  padding: 0px 10px;
  width: fit-content;
  margin: auto;
}

.New {
  color: white;
  background-color: var(--main-color);
  border-radius: 12px;
  width: fit-content;
  padding: 0px 10px;
  margin: auto;
}

.Modified {
  color: var(--main-color);
  border: 1px solid var(--main-color);
  border-radius: 12px;
  width: fit-content;
  padding: 0px 10px;
  margin: auto;
}
