body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.85rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100vh;
}

:root {
  --main-color: #97d700;
  --button-click: #82ba00;
  /* --main-color: #2e449b;
  --button-click: #147772; */
  --background-color: #f2f2f2;
  --menu-border-color: #b9b9b9;
  /* --main-color: #ff681c; */
}
